.image-popup-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .popup-image {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .popup-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .popup-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-button:hover {
    opacity: 0.9;
  }
  
  .popup-button.delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  .popup-button.close-button {
    background-color: #6c757d;
    color: white;
  }
  
  .popup-button:not(.delete-button):not(.close-button) {
    background-color: #007bff;
    color: white;
  }