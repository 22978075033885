/* src/components/Mobile/MobileOrderImagesModal.css */

.mobile-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  overflow-y: auto;
  padding: 20px;
}

.mobile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.frame-type-display {
  margin-top: 10px;
}

.error-message {
  color: red;
}

.mobile-select-multiple-button,
.mobile-print-selected-button,
.mobile-print-order-button,
.mobile-close-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mobile-image-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.mobile-image-item {
  position: relative;
  margin: 5px;
}

.mobile-image-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.mobile-image-item img.selected {
  border: 2px solid #007bff;
}

.mobile-image-checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
}

.mobile-image-frame-select {
  margin-top: 5px;
  width: 100%;
  padding: 5px;
}

.greyscale-option {
  margin-top: 5px;
}

.greyscale-option input {
  margin-right: 5px;
}

/* MobileImagePopupModal.css */

.mobile-image-popup-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-image-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.popup-image {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  margin-bottom: 10px;
}

.popup-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.popup-button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-button:hover {
  opacity: 0.9;
}

.popup-button.delete-button {
  background-color: #dc3545;
  color: white;
}

.popup-button.close-button {
  background-color: #6c757d;
  color: white;
}

.popup-button:not(.delete-button):not(.close-button) {
  background-color: #007bff;
  color: white;
}

.mobile-order-print-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.mobile-order-preset-select {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

.mobile-print-order-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}