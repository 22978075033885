.order-list-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}

.order-list {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.order-list th,
.order-list td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.order-list th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.order-list tr:hover {
  background-color: #f5f5f5;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
}

.status-ready { background-color: #E6FFEE; color: #006F37; }
.status-cancelled { background-color: #FFE6E6; color: #8B0000; }
.status-printed { background-color: #E6F9FF; color: #006F9F; }
.status-received { background-color: #FFFEE6; color: #8B7500; }
.status-badge.fulfilled { background-color: #d4edda; color: #155724; }
.status-badge.unfulfilled { background-color: #f8d7da; color: #721c24; }

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sort-indicator {
  margin-left: 5px;
}

.order-id-link {
  cursor: pointer;
  color: #000;
  font-weight: 700;
}

.order-id-link:hover {
  color: #0056b3;
}

@media screen and (max-width: 768px) {
  .order-list,
  .order-list tbody,
  .order-list tr,
  .order-list td {
    display: block;
    width: 100%;
  }
  
  .order-list tr {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .order-list td {
    text-align: right;
    padding-left: 50%;
    position: relative;
    border-bottom: 1px solid #eee;
  }
  
  .order-list td:before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  
  .order-list thead {
    display: none;
  }
  
  .order-list td:last-child {
    border-bottom: 0;
  }
}

/* Column widths - these will only apply on larger screens */
@media screen and (min-width: 769px) {
  .orderid-column { width: 6rem; }
  .date-column { width: 6rem; }
  .customername-column { width: 12rem; }
  .status-column { width: 6rem; }
  .frametype-column { width: 12rem; }
  .frameamount-column { width: 4rem; }
  .phonenumber-column { width: 9rem; }
  .city-column { width: 9rem; }
  .price-column { width: 6rem; }
}