.crop-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .crop-container {
    position: relative;
    width: 300px;
    height: 300px;
    background-color: #333;
    overflow: hidden;
  }
  
  .reactEasyCrop_Container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .reactEasyCrop_CropArea {
    color: rgba(255, 255, 255, 0.64);
  }
  
  .controls {
    margin-top: 20px;
    width: 300px;
  }
  
  .zoom-range {
    width: 100%;
  }
  
  .crop-actions {
    margin-top: 20px;
  }
  
  .crop-actions button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .crop-actions button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .crop-actions button:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  .crop-actions button:last-child {
    background-color: #f44336;
  }
  
  .crop-actions button:last-child:hover:not(:disabled) {
    background-color: #d32f2f;
  }