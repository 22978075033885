.mobile-dashboard {
    padding: 10px;
  }
  
  .mobile-header {
    display: flex;
    align-items: center;
  }
  
  .hamburger-menu {
    background: none;
    border: none;
    font-size: 24px;
    margin-right: 15px;
    cursor: pointer;
  }
  
  .mobile-filter-tabs {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .mobile-filter-tabs {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .filter-tab {
    padding: 10px 15px;
    margin-right: 5px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .filter-tab:last-child {
    margin-right: 0;
  }
  
  .filter-tab.active {
    background-color: #007bff;
    color: white;
    border-radius: 20px;
  }
  
  .filter-tab:hover {
    background-color: #e0e0e0;
  }
  
  .filter-tab.active:hover {
    background-color: #0056b3;
  }
  
  .filter-tab:active {
    transform: scale(0.95);
  }
  
  /* Disable default button styles on touch devices */
  @media (hover: none) {
    .filter-tab:hover {
      background-color: #f0f0f0;
    }
    
    .filter-tab.active:hover {
      background-color: #007bff;
    }
  }
  
  .mobile-search input {
    width: 90%;
    height: 2.4rem;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .loading,
  .error {
    text-align: center;
    padding: 20px;
  }
  
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background-color: white;
    z-index: 1001;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
  }
  
  .sidebar-container.closed {
    transform: translateX(-100%);
  }