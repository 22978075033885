.order-summary {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  padding: 0.875rem;
  text-align: center;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-summary .icon {
  font-size: 1.125rem;
  margin-bottom: 0.375rem;
  color: #6c757d;
}

.order-summary h3 {
  margin: 0;
  font-size: 0.8125rem;
  color: #6c757d;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-summary p {
  margin: 0.25rem 0 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: #495057;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-summary .main-count {
  font-size: 1.125rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Left align for currency values */
.order-summary .main-count[style*="currency"] {
  justify-content: flex-start;
}

.order-summary .previous-count {
  font-size: 0.75rem;
  font-weight: normal;
  margin-top: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #6c757d;
}

.order-summary .arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.125rem;
  flex-shrink: 0;
}

.order-summary .arrow.up {
  color: rgb(116, 197, 116);
}

.order-summary .arrow.down {
  color: rgb(247, 140, 140);
}

@media screen and (min-width: 771px) and (max-width: 1200px) {
  .order-summary {
    padding: 0.75rem;
  }

  .order-summary .icon {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  .order-summary h3 {
    font-size: 0.75rem;
  }

  .order-summary p,
  .order-summary .main-count {
    font-size: 1rem;
    margin-top: 0.125rem;
  }

  .order-summary .previous-count {
    font-size: 0.6875rem;
    margin-top: 0.125rem;
  }

  .order-summary .arrow {
    font-size: 0.6875rem;
    width: 0.75rem;
    height: 0.75rem;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .order-summary {
    padding: 0.8125rem;
  }

  .order-summary .icon {
    font-size: 1.0625rem;
  }

  .order-summary h3 {
    font-size: 0.775rem;
  }

  .order-summary p,
  .order-summary .main-count {
    font-size: 1.0625rem;
  }

  .order-summary .previous-count {
    font-size: 0.725rem;
  }

  .order-summary .arrow {
    font-size: 0.725rem;
    width: 0.8125rem;
    height: 0.8125rem;
  }
}
