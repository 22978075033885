.images-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.uploaded-images-grid {
  margin-top: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
}

.uploaded-images-grid h2 {
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}
