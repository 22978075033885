.sales-graph-container {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  margin: 24px 0;
  border: 1px solid #f0f0f0;
  width: 50%;
  margin-right: auto;
}

.sales-graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
}

.sales-graph-title {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
  letter-spacing: -0.02em;
}

.sales-graph-controls {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.toggle-group {
  display: flex;
  gap: 2px;
  background: #f5f5f5;
  padding: 2px;
  border-radius: 6px;
  margin-left: auto;
}

.sales-graph-metrics {
  display: flex;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 24px;
  margin-left: 60px;
  position: relative;
}

.metric-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.metric-toggle:hover {
  background: rgba(0, 0, 0, 0.05);
}

.metric-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid;
  border-color: inherit;
  transition: background-color 0.2s ease;
}

.metric-toggle span {
  font-size: 13px;
  color: #637381;
  font-weight: 500;
}

.periods-group {
  flex-wrap: wrap;
  max-width: 400px;
  gap: 4px;
  padding: 4px;
}

.toggle-button {
  background: none;
  border: none;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  color: #637381;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.toggle-button:not(:disabled):hover {
  background: rgba(0, 0, 0, 0.05);
}

.toggle-button:disabled {
  cursor: not-allowed;
}

.toggle-button.active {
  background: white;
  color: #1a1a1a;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sales-graph {
  height: 150px;
  width: 100%;
}

.sales-graph-tooltip {
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #f0f0f0;
  min-width: 120px;
}

.sales-graph-tooltip .date {
  color: #637381;
  font-size: 13px;
  margin: 0 0 6px 0;
  font-weight: 500;
}

.sales-graph-tooltip .amount {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  letter-spacing: -0.01em;
  padding: 4px 0;
}

/* Shopify-style axis customization */
.recharts-cartesian-axis-line {
  stroke-width: 1px;
}

.recharts-cartesian-grid-horizontal line {
  stroke: #f5f5f5;
}

.recharts-text {
  fill: #637381;
  font-size: 13px;
  font-weight: 500;
}

.recharts-default-tooltip {
  border: none !important;
  box-shadow: none !important;
}

.recharts-area-curve {
  transition: all 0.3s ease;
}

.recharts-layer:hover .recharts-area-curve {
  opacity: 0.9;
}

/* Multiple Y-axes styling */
.recharts-yAxis .recharts-cartesian-axis-tick-value {
  font-size: 12px;
}

/* Ensure Y-axis lines are visible */
.recharts-cartesian-axis.yAxis line {
  stroke: #E5E5E5;
}

/* Add spacing between tooltip items */
.sales-graph-tooltip .amount + .amount {
  margin-top: 4px;
  border-top: 1px solid #f5f5f5;
}

/* Ensure proper spacing for right Y-axis */
.recharts-wrapper {
  padding-right: 10px;
}
