.mobile-image-popup-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    outline: none;
  }
  
  .mobile-image-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mobile-image-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mobile-popup-image {
    max-width: 100%;
    max-height: 60vh;
    object-fit: contain;
  }
  
  .mobile-popup-image-name {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .mobile-popup-actions {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
  }
  
  .mobile-popup-delete-button,
  .mobile-popup-print-button,
  .mobile-popup-close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .mobile-popup-delete-button {
    background-color: #f44336;
    color: white;
  }
  
  .mobile-popup-print-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .mobile-popup-close-button {
    background-color: #808080;
    color: white;
    margin-top: 10px;
  }