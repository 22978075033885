  .mobile-layout {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }
  
  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 16px 16px;
    background-color: #f0f0f0; /* Adjust as needed */
  }

  .hamburger-menu {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .mobile-main-content {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background-color: white;
    z-index: 1001;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
  }
  
  .sidebar-container.closed {
    transform: translateX(-100%);
  }
  
  .logout-button {
    font-size: 16px;
    cursor: pointer;
    border: none;
    background: none;
  }