.mobile-crop-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
  
  .mobile-crop-container {
    position: relative;
    width: 90vw;
    height: 90vw;
    max-width: 300px;
    max-height: 300px;
    background-color: #333;
    overflow: hidden;
  }
  
  .mobile-crop-controls {
    margin-top: 20px;
    width: 90%;
    max-width: 300px;
  }
  
  .mobile-zoom-range {
    width: 100%;
  }
  
  .mobile-crop-actions {
    margin-top: 20px;
  }
  
  .mobile-crop-actions button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .mobile-crop-actions button:last-child {
    background-color: #f44336;
  }
  
  .mobile-crop-actions button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .mobile-crop-actions button:hover:not(:disabled) {
    opacity: 0.8;
  }