/* src/components/OrderImagesModal.css */

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.image-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.image-item img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.image-item img.selected {
  border: 2px solid #007bff;
}

.image-checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
}

.image-frame-select {
  margin-top: 5px;
  width: 100%;
  padding: 5px;
}

.greyscale-option {
  margin-top: 5px;
}

.greyscale-option input {
  margin-right: 5px;
}

.image-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 5px;
}

.image-actions button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.image-actions button:hover {
  background-color: #0056b3;
}

.select-multiple-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.multiple-actions {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.print-selected-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.print-order-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.frame-type-display {
  margin-top: 10px;
}

.error-message {
  color: red;
}

.delete-confirmation {
  margin-top: 20px;
}

.delete-confirmation p {
  margin-bottom: 10px;
}

.delete-confirmation button {
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
}

.delete-confirmation button:first-of-type {
  background-color: #dc3545;
  color: white;
}

.delete-confirmation button:last-of-type {
  background-color: #6c757d;
  color: white;
}

/* ImagePopupModal.css */

.image-popup-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
}

.image-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99998;
}

.popup-image {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  margin-bottom: 10px;
}

.popup-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.popup-button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-button:hover {
  opacity: 0.9;
}

.popup-button.delete-button {
  background-color: #dc3545;
  color: white;
}

.popup-button.close-button {
  background-color: #6c757d;
  color: white;
}

.popup-button:not(.delete-button):not(.close-button) {
  background-color: #007bff;
  color: white;
}
