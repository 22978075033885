.app-layout {
    display: flex;
    min-height: 100vh;
  }
  
  .sidebar {
    background-color: #fff;
    color: white;
  }
  
  .main-content {
    flex-grow: 1;
    background-color: #FFFDFB;
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }