/* Hide mobile-specific elements on desktop */
@media screen and (min-width: 769px) {
  .daily-stats,
  .aggregation-group {
    display: none;
  }
}

/* Mobile-specific styles for Sales Graph */
@media screen and (max-width: 768px) {
  .sales-graph-container {
    width: 90%;
    padding: 16px;
    margin: 0 auto;
    border-radius: 8px;
  }

  .sales-graph-header {
    margin-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .sales-graph-title {
    display: none;
  }

  .sales-graph-controls {
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }

  .aggregation-group-desktop {
    display: none;
  }

  .toggle-group {
    width: 100%;
    justify-content: space-between;
    padding: 4px;
    background: #f5f5f5;
  }

  .sales-graph-metrics {
    margin-left: 0;
    margin-top: 16px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .metric-toggle {
    padding: 6px 10px;
    min-height: 36px;
  }

  .metric-circle {
    width: 14px;
    height: 14px;
  }

  .metric-toggle span {
    font-size: 14px;
  }

  .periods-group {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    padding: 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .toggle-button {
    padding: 6px 6px;
    font-size: 12px;
    min-height: 32px;
    min-width: 40px;
    flex: 0 0 auto;
    text-align: center;
    white-space: nowrap;
  }

  .sales-graph {
    height: 250px;
    margin: 0 -16px;
    position: relative;
  }

  .sales-graph .recharts-responsive-container {
    height: 250px !important;
  }

  .sales-graph-tooltip {
    padding: 12px;
    min-width: 140px;
  }

  .sales-graph-tooltip .date {
    font-size: 14px;
  }

  .sales-graph-tooltip .amount {
    font-size: 18px;
  }

  /* Aggregation buttons below graph */
  .aggregation-group {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
    padding: 0;
  }

  .aggregation-group .toggle-button {
    min-width: 70px;
    padding: 8px 12px;
  }

  /* Daily stats section - Mobile only */
  .daily-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;
  }

  .stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 12px 8px;
    background: #f9f9f9;
    border-radius: 8px;
  }

  .stat-label {
    font-size: 13px;
    color: #637381;
    margin-bottom: 4px;
  }

  .stat-value {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
  }

  /* Adjust chart elements for touch */
  .recharts-text {
    font-size: 12px;
  }

  .recharts-cartesian-axis-tick-value {
    font-size: 11px;
  }

  /* Ensure proper spacing for touch targets on data points */
  .recharts-dot {
    r: 4;
  }

  /* Adjust tooltip position to prevent cutoff */
  .recharts-tooltip-wrapper {
    z-index: 1000;
  }

  /* Ensure proper spacing for right Y-axis on mobile */
  .recharts-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    height: 250px !important;
  }

  /* Chart margins and spacing for mobile */
  .recharts-wrapper .recharts-cartesian-grid {
    margin: 0;
  }

  /* Bar chart specific styles */
  .recharts-bar {
    shape-rendering: crispEdges;
  }

  .recharts-bar-rectangle {
    width: 8px !important;
  }

  /* Grid lines */
  .recharts-cartesian-grid-horizontal line {
    stroke: #f0f0f0;
    stroke-width: 1;
  }

  .recharts-cartesian-grid-vertical {
    display: none;
  }

  /* Adjust spacing */
  .sales-graph {
    margin: 0 -8px;
    padding: 0 8px;
  }

  /* Adjust Y-axis positioning */
  .recharts-yAxis {
    transform: translate(0, 0) !important;
  }

  .recharts-yAxis.yAxis-right {
    transform: translate(0, 0) !important;
  }
}
