.mobile-order-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .mobile-order-item {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .mobile-order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .mobile-order-id {
    font-weight: bold;
  }
  
  .mobile-status-badge {
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .mobile-order-details p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .status-ready {
    background-color: #E6FFEE;
    color: #006F37;
  }
  
  .status-cancelled {
    background-color: #FFE6E6;
    color: #8B0000;
  }
  
  .status-printed {
    background-color: #E6F9FF;
    color: #006F9F;
  }
  
  .status-received {
    background-color: #FFFEE6;
    color: #8B7500;
  }