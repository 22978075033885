.sidebar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #333;
  border-radius: 8px;
  padding: 6px 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.sidebar ul li {
  margin: 0;
}

.sidebar ul li a,
.sidebar ul li button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  color: #C6DBCD;
  transition: color 0.2s ease;
}

.sidebar ul li a svg,
.sidebar ul li button svg {
  width: 18px;
  height: 18px;
}

.sidebar ul li a:hover,
.sidebar ul li button:hover {
  color: #087E63;
}

.sidebar ul li a.active svg {
  color: #087E63;
}
