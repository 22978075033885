.uploader-container {
  width: 100%;
  padding: 20px;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #fafafa;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone.active {
  border-color: #2196f3;
  background: #e3f2fd;
}

.dropzone.uploading {
  border-style: solid;
  background: #f5f5f5;
  cursor: not-allowed;
}

.upload-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.upload-icon {
  font-size: 48px;
}

.upload-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2196f3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.upload-error {
  margin-top: 10px;
  color: #f44336;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #ffebee;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
